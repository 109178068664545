<template>
  <v-main>
    <v-container style="max-width: 1140px;">
      <h3 class="py-4" ref="top">遊び方</h3>
      <ul>
        <li><a @click="scrollToAnchorPoint('about')">神様のサイコロとは</a></li>
        <li><a @click="scrollToAnchorPoint('point')">ポイント、得点、レベル、信用ポイント</a></li>
        <li><a @click="scrollToAnchorPoint('lifecycle')">予想の流れ</a></li>
        <li><a @click="scrollToAnchorPoint('profile')">プロフィールを作成しよう！</a></li>
        <li><a @click="scrollToAnchorPoint('bonus')">デイリーボーナスをもらおう！</a></li>
        <li><a @click="scrollToAnchorPoint('trade')">予想に参加しよう！</a></li>
        <li><a @click="scrollToAnchorPoint('report')">投票に参加しよう！</a></li>
        <li><a @click="scrollToAnchorPoint('comment')">コメント欄で交流しよう！</a></li>
        <li><a @click="scrollToAnchorPoint('prediction')">未来予想を作ってみよう！</a></li>
        <li><a @click="scrollToAnchorPoint('event')">イベントに参加しよう！</a></li>
        <li><a @click="scrollToAnchorPoint('odds')">オッズはどうやって決まるの？</a></li>
        <li><a @click="scrollToAnchorPoint('bad')">不快な未来予想やコメントを見つけたらどうすれば良い？</a></li>
        <li><a @click="scrollToAnchorPoint('origin')">アプリ名の由来は？</a></li>
        <li><a @click="scrollToAnchorPoint('delete')">アカウントを削除するには？</a></li>
        <li><a @click="scrollToAnchorPoint('inquiry')">他に分からないことがあったら？</a></li>
      </ul>
      <h3 class="pt-8 pb-4" ref="about">神様のサイコロとは</h3>
      <p>
        神様のサイコロとはスポーツやエンタメ、政治・経済などの未来を予想して楽しむ安心・安全な予想プラットフォームです。
        <br>
        ユーザーは未来予想を作成したり、答えを予想したり、正解を決めるために投票に参加したりすることができます。
        <br>
        見事予想が的中すると得点が付与され、獲得した得点によってランキングが決まるので、上位を目指して頑張りましょう！
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="point">ポイント、得点、レベル、信用ポイント</h3>
      <p>
        神様のサイコロではポイントを使用して得点を稼ぎ、得点によってランキングを競います。また得点を増やすことによってレベルも上がっていきます。
        <br>
        それ以外に正解に投票することによって得られる信用ポイントと言うものもありますので、それぞれどのようなものか説明します。
        <br>
        <dl>
          <dt class="font-weight-bold">ポイント</dt>
          <dd class="pl-4">
            予想に使用して得点の原資になるものです。ポイントは以下の方法で獲得できますが、月を跨いで持ち越すことはできません。
            <ul class="pl-8">
              <li>毎日のログイン</li>
              <li>正解への投票</li>
              <li>月間ランキング入賞</li>
              <li>レベルアップ</li>
              <li>広告視聴</li>
            </ul>
          </dd>
          <dt class="pt-4 font-weight-bold">得点</dt>
          <dd class="pl-4">
            得点は予想に正解することによってのみ獲得できます。得点はなくなることはありませんが、ランキングでは特定の期間に獲得した得点で順位を競います。
          </dd>
          <dt class="pt-4 font-weight-bold">レベル</dt>
          <dd class="pl-4">
            レベル（とレベルアップメーター）は得点をビジュアルで表したものです。得点を増やしていってレベルアップメーターが満タンになると、レベルが1つ上がります。
            <br>
            レベルが上がると、ルーレットを回して報酬（アイテムやポイント）を獲得することができます。
          </dd>
          <dt class="pt-4 font-weight-bold">信用ポイント</dt>
          <dd class="pl-4">
            信用ポイントは正解に投票することによって獲得できますが、不正解に投票すると半分になります。信用ポイントはあなたに対する信用のバロメータです！
            <br>
            今のところ、信用ポイントは再投票に参加する権利を得るために使われているだけですが、今後は神様のサイコロの運営方針を決める投票などにも活用していく予定です。
          </dd>
        </dl>
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="lifecycle">予想の流れ</h3>
      <p>
        未来予想を作成してから結果が確定するまでの流れは次の通りです。
      </p>
      <p>
        <v-card>
          <v-card-text>
            未来予想作成
            <br>
            &nbsp;&nbsp;↓ 開催前
            <br>
            予想開始
            <br>
            &nbsp;&nbsp;↓ 開催中
            <br>
            予想終了＆結果投票開始
            <br>
            &nbsp;&nbsp;↓ 確定中
            <br>
            結果の事実が確定
            <br>
            &nbsp;&nbsp;↓ 確定中
            <br>
            結果投票終了
            <br>
            &nbsp;&nbsp;↓
            <br>
            異議申し立て終了
            <br>
            <br>
            1. 異議なしの場合
            <br>
            結果確定
            <br>
            &nbsp;&nbsp;↓ 確定済
            <br>
            <br>
            2. 異議ありの場合
            <br>
            再投票開始
            <br>
            &nbsp;&nbsp;↓ 確定中
            <br>
            再投票終了
            <br>
            &nbsp;&nbsp;↓
            <br>
            異議申し立て終了
            <br>
            <br>
            2-1. 異議なしの場合
            <br>
            結果確定
            <br>
            &nbsp;&nbsp;↓ 確定済
            <br>
            <br>
            2-2. 異議ありの場合
            <br>
            未来予想不成立で終了
          </v-card-text>
        </v-card>
      </p>
      <p>
        予想締切時間は出題者が決めます。予想の結果を投票で決めるため、結果に不服がある場合は異議申し立てを行うことができます。
        異議申し立ては投票終了の1時間後まで受け付けています。異議申し立てが回答者数の10%を超えると再投票となります。
        再投票に参加できるのは正解に投票することによって得られる信用ポイントが上位50%のユーザーです。
        再投票の結果にも回答者数の10%を超える異議が申し立てられた場合は未来予想不成立となります。
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="profile">プロフィールを作成しよう！</h3>
      <p>
        プロフィールを作成するには画面下のメニューから「マイページ」をタップします。
        <br>
        「編集」ボタンをタップすると、アイコンとユーザー名、一言メッセージを編集することができます。
        <br>
        他のユーザーは未来予想のコメント欄やランキングからあなたのプロフィールを閲覧することができます。
      </p>
      <p>
        <v-row>
          <!-- <v-col><v-img src="@/assets/how-to-play/profile1.png" width="300"/></v-col> -->
          <!-- <v-col><v-img src="@/assets/how-to-play/profile2.png" width="300"/></v-col> -->
          <v-col><v-img src="@/assets/how-to-play/profile3.png" width="300"/></v-col>
        </v-row>
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="bonus">デイリーボーナスをもらおう！</h3>
      <p>
        ボーナスをもらうには画面左上のポイント表示部分をタップしてから「広告を見てボーナスを2倍ゲット」または「ボーナスをゲット」ボタンをタップします。
        <br>
        ボーナスが受け取れるのは1日1回までです。
        <br>
        ※ボーナスが受け取れるようになるとウォレットアイコンに「！」マークが表示されます。
      </p>
      <p>
        <v-row>
          <!-- <v-col><v-img src="@/assets/how-to-play/bonus1.png" width="300"/></v-col> -->
          <v-col><v-img src="@/assets/how-to-play/bonus2.png" width="300"/></v-col>
          <!-- <v-col><v-img src="@/assets/how-to-play/bonus3.png" width="300"/></v-col> -->
        </v-row>
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="trade">予想に参加しよう！</h3>
      <p>
        予想に参加するには画面下のメニューから「未来予想」をタップした後、「開催中」タブの中から参加したい未来予想を選択します。
        <br>
        次に表示された選択肢の中から正解だと思うものにチェックをつけて使用するポイントを入力します。
        <br>
        またアイテムを所有している場合はアイテムを使用して更に高得点を狙うことも可能です。
        <br>
        予想が的中すると「使用ポイント × オッズ」分の得点が付与されます。（オッズ〇倍アイテムを使った場合は〇倍の得点が付与されます。）
        <br>
        ※オッズは参加者数によって変動し、予想が締め切られた時点で確定します。
        <br>
        最後に「未来予想に回答する」ボタンをタップして回答内容に問題がなければ「OK」をタップします。
        <br>
        予想が締め切られるまでの間は何度でも予想を変更したり取り下げたりすることができます。
      </p>
      <p>
        <v-row>
          <!-- <v-col><v-img src="@/assets/how-to-play/trade1.png" width="300"/></v-col> -->
          <!-- <v-col><v-img src="@/assets/how-to-play/trade2.png" width="300"/></v-col> -->
          <v-col><v-img src="@/assets/how-to-play/trade3.png" width="300"/></v-col>
        </v-row>
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="report">投票に参加しよう！</h3>
      <p>
        投票に参加するには画面下のメニューから「未来予想」をタップした後、「確定中」タブの中から参加したい未来予想を選択します。
        <br>
        次に表示された選択肢の中から正解だと思うものにチェックをつけます。
        <br>
        最後に「予想結果を投票する」ボタンをタップして投票先に問題がなければ「OK」をタップします。
        <br>
        投票は正解を決めるために行うので、必ず正しい選択肢に投票してください。
        <br>
        あなたの投票先が正解として確定すると、投票者報酬と信用ポイントが付与されます。
        <br>
        もし正解以外の選択肢に投票すると信用ポイントが半分になります。
        <br>
        信用ポイントは未来予想の結果に異議が申し立てられた場合に再投票に参加できるユーザーを決めるために使用されます。
        <br>
        投票はポイントを持っていなくても参加できるので、ポイントを使い果たしてしまったら投票でコツコツ稼ぎましょう！
      </p>
      <p>
        <v-row>
          <!-- <v-col><v-img src="@/assets/how-to-play/report1.png" width="300"/></v-col> -->
          <!-- <v-col><v-img src="@/assets/how-to-play/report2.png" width="300"/></v-col> -->
          <v-col><v-img src="@/assets/how-to-play/report3.png" width="300"/></v-col>
        </v-row>
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="comment">コメント欄で交流しよう！</h3>
      <p>
        各未来予想の下にはコメント欄があるので、他のユーザーと意見交換をしたりすることができます。
        <br>
        予想をするための参考として読んでみるのも良いですし、自分の予想の根拠を書いて他のユーザーから返信をもらうのも良いですね！
        <br>
        また、予想が締め切られた後も結果について実況中継をしたり、感想を語り合ったりと使い方はあなた次第です！
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="prediction">未来予想を作ってみよう！</h3>
      <p>
        未来予想を作るには画面下のメニューから「新規作成」をタップした後、画面の指示に従って作成します。
        <br>
        作成した未来予想は「未来予想」メニューの「開催前」→「出題準備中」タブに表示されます。
        <br>
        予想が始まるまでは何度でも編集できますが、予想が始まると編集ができなくなり、補足を追記できるのみになるので注意してください。
        <br>
        作成した未来予想の結果が確定すると、出題者報酬が付与されます。
        <!-- <br>
        回答数と投票数が一定の基準に満たなかった場合、未来予想は不成立となります。
        <br>
        不成立となった場合、回答者が使用したポイントは払い戻されますが、出題者が使用したポイントは没収されます。 -->
      </p>
      <p>
        <v-row>
          <v-col><v-img src="@/assets/how-to-play/creator1.png" width="300"/></v-col>
          <!-- <v-col><v-img src="@/assets/how-to-play/creator2.png" width="300"/></v-col> -->
        </v-row>
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="event">イベントに参加しよう！</h3>
      <p>
        イベントに参加するには画面下のメニューから「未来予想」をタップした後、「イベント」タブの中から参加したいイベントを選択します。
        <br>
        イベントページにはイベントの開催概要が表示されるので、内容を確認したら「イベント参加中の予想」をタップして表示された未来予想の中から参加したい未来予想を選択します。
        <br>
        イベントで入賞すると報酬が付与されるので、上位を目指して予想しましょう！
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="odds">オッズはどうやって決まるの？</h3>
      <p>
        オッズの計算は以下のように行われます。
        <br>
        オッズ ＝ 全回答者の使用ポイントの合計 ÷ その選択肢の回答者の使用ポイントの合計
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="bad">不快な未来予想やコメントを見つけたらどうすれば良い？</h3>
      <p>
        神様のサイコロでは未来予想とコメントについて通報を行うことができます。
        <br>
        通報を受けると、運営の判断で未来予想やコメントの削除、悪質と判断した場合は該当ユーザーの強制退会等の対処を行います。
        <br>
        また、未来予想の作成者やコメントをしたユーザーのアイコンをタップすると、そのユーザーをブロックすることもできます。
        <br>
        ユーザーをブロックすると、そのユーザーが作成した未来予想やコメントが表示されなくなります。
        <br>
        また、そのユーザーはランキングにも表示されなくなります。
        <br>
        尚、ブロックしたユーザーは、マイページでブロックを解除できます。
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="origin">アプリ名の由来は？</h3>
      <p>
        その昔、かのアインシュタインは「神はサイコロを振らない。」と言ったそうです。
        <br>
        でも、未来に起こることがもう全て決まっていると思うと、今の努力が無駄に思えてきて空しくなってしまいます。
        <br>
        なので、真実はどうあれ「神はサイコロを振る。」と考えた方が賢明ですよね。
        <br>
        神様が振るサイコロの目を予想して楽しむアプリという事で「神様のサイコロ」と名付けました。
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="delete">アカウントを削除するには？</h3>
      <p>
        アカウントを削除するには画面左上の「<v-icon class="mr-1">mdi-menu</v-icon>」ボタンをタップして「設定」を選択します。
        <br>
        次にアカウント管理の「アカウントを削除」をタップします。
        <br>
        すると、削除確認のダイアログが表示されるので、「はい」をタップすると、アカウントと関連データが削除されます。
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <h3 class="pt-8 pb-4" ref="inquiry">他に分からないことがあったら？</h3>
      <p>
        ここに書いてあること以外でもし何か分からないことがあれば、<a href="#" @click.prevent="inquiry">問い合わせ画面</a>からお問い合わせをお願いします。
      </p>
      <div class="d-flex flex-row justify-end">
        <a @click="scrollToAnchorPoint('top')">TOPに戻る</a>
      </div>
      <br>
      <br>
      <br>
      <br>
      <br>
    </v-container>
  </v-main>
</template>

<script>
  import mixin from '@/util/mixin'

  export default {
    mixins: [mixin],
    methods: {
      inquiry() {
        this.$router.push({ path: '/inquiry' })
      },
      scrollToAnchorPoint(refName) {
          const el = this.$refs[refName]
          el.scrollIntoView({ behavior: 'smooth'})
      }
    },
  }
</script>

<style scoped>
</style>